/* v8 ignore start */
import type { Environment } from '../models/environment';

export const useEnvironment: () => Environment = () => {
  const {
    DEV,
    MODE,
    VITE_AUTH0_DOMAIN,
    VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_AUDIENCE,
    VITE_ENABLE_PRODUCTION_PROFILING,
    VITE_API_URL,
    VITE_MIXPANEL_TOKEN,
  } = import.meta.env;

  const STAGING = MODE === 'test';
  const PROD = import.meta.env?.PROD && !STAGING;

  return {
    auth0Audience: VITE_AUTH0_AUDIENCE,
    auth0ClientId: VITE_AUTH0_CLIENT_ID,
    auth0Domain: VITE_AUTH0_DOMAIN,
    apiUrl: VITE_API_URL,
    enableProductionProfiling: VITE_ENABLE_PRODUCTION_PROFILING === 'true',
    isDev: DEV,
    isProd: PROD,
    isStaging: STAGING,
    mixpanelToken: VITE_MIXPANEL_TOKEN,
  };
};
/* v8 ignore stop */
